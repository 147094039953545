import React from 'react'
import Faqs from '../faq'
import { FaqSection, FaqTitle, FaqContainer, FaqButton } from './styles'

const Faq = ({ faqs, checkedLocale }) => {

	return (
		<>
			<FaqSection>
				<FaqTitle>Frequently Asked Questions</FaqTitle>
				<FaqContainer>
					{faqs.map((item, i) => {
						return (
							<Faqs
								key={i}
								title={item.title}
								body={item?.content?.json ? item.content.json : item.description.description}
							/>
						)
					})}
				</FaqContainer>
				<FaqButton to={`${checkedLocale}/support/faq/`}>View more FAQ's</FaqButton>
			</FaqSection>
		</>
	)
}

export default Faq
