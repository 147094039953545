import React from 'react'
import filterLocale from '../components/utils/filter-locale'
import Layout from '../components/layout'
import Meta from '../components/meta'
import { graphql } from 'gatsby'
import Hero from '../components/product-line/hero'
import Products from '../components/product-line/products'
import LineDescription from '../components/product-line/line-description'
import Faq from '../components/product-line/faq'
import WhichModel from '../components/which-model'
import localeCheck from '../components/utils/locale-check'
import getCurrency from '../components/utils/currency'

const ProductLines = ({
	pageContext: { series, locale, defaultLocale },
	data: { line, lastBanner, productImage, lineLg, lineSm },
}) => {
	const productSeries = series !== 'O3 Series' ? series : 'O₃ Series'
	const prodSeries = productSeries !== 'Wave Line' ? productSeries : 'Wave'
	const [{ node: filteredLine }] = filterLocale(line.edges, locale)
	const [{ node: filteredLastBanner }] = filterLocale(lastBanner.edges, locale)
	const [{ node: linelg }] = filterLocale(lineLg.edges, locale)
	const [{ node: linesm }] = filterLocale(lineSm.edges, locale)

	const {
		seoTitle,
		seoKeywords,
		seoDescription,
		body,
		active,
		series: seriesName,
		banner: {
			mainActionText,
			mainActionUrl,
			secondActionText,
			secondActionUrl,
			description,
			image,
			backgroundImage: herobg,
			videoBanner: videourl,
		},
		frequentlyAskedQuestions,
		brochure,
		manual,
	} = filteredLine
	const bannerSources = [
		{
			...herobg.fluid,
			srcSet: `${herobg.fluid.src} 1920w`,
			srcSetWebp: `${herobg.fluid.srcWebp} 1920w`,
			media: `(min-width: 1350px)`,
		},
		{
			...linelg.banner.backgroundImage.fluid,
			srcSet: `${linelg.banner.backgroundImage.fluid.src} 1349w`,
			srcSetWebp: `${linelg.banner.backgroundImage.fluid.srcWebp} 1349w`,
			media: `(min-width: 1280px) and (max-width: 1349px)`,
		},
		{
			...linesm.banner.backgroundImage.fluid,
			srcSet: `${linesm.banner.backgroundImage.fluid.src} 1279w`,
			srcSetWebp: `${linesm.banner.backgroundImage.fluid.srcWebp} 1279w`,
			media: `(min-width: 1024px) and (max-width: 1279px)`,
		},
	]

	const checkedLocale = localeCheck(locale, defaultLocale)
	const currency = getCurrency(locale)

	return (
		<Layout>
			<Meta
				title={seoTitle}
				keywords={seoKeywords}
				description={seoDescription}
				lang={locale}
			></Meta>
			<Hero
				{...{
					mainActionText,
					mainActionUrl,
					secondActionText,
					secondActionUrl,
					description,
					videourl,
					image,
					seriesName,
					bannerSources,
					active,
					locale,
				}}
			/>
			<LineDescription body={body} />
			<Products
				productImage={productImage}
				prodSeries={prodSeries}
				locale={locale}
				checkedLocale={checkedLocale}
				currency={currency}
			/>
			<Faq faqs={frequentlyAskedQuestions} checkedLocale={checkedLocale} />
			{!/Mineral Swim|Ozone Swim/.test(seoTitle) && (
				<WhichModel data={filteredLastBanner} checkedLocale={checkedLocale} />
			)}
		</Layout>
	)
}

export const ProductLinePageQuery = graphql`
	query ProductLine($slug: String!, $series: String!) {
		line: allContentfulPage(filter: { slug: { eq: $slug } }) {
			edges {
				node {
					node_locale
					seoTitle
					seoKeywords
					seoDescription
					slug
					series
					active
					body {
						json
					}
					banner {
						hook
						mainActionText
						mainActionUrl
						secondActionText
						secondActionUrl
						backgroundImage {
							fluid(maxWidth: 1920, maxHeight: 720, quality: 60) {
								src
								srcWebp
							}
						}
						image {
							description
							file {
								url
							}
						}
						videoBanner {
							file {
								url
							}
						}
						description {
							json
						}
					}
					frequentlyAskedQuestions {
						title
						description {
							description
						}
						content {
							json
						}
					}
					media {
						file {
							url
						}
					}
					brochure {
						file {
							url
						}
					}
					manual {
						file {
							url
						}
					}
				}
			}
		}
		lineLg: allContentfulPage(filter: { slug: { eq: $slug } }) {
			edges {
				node {
					node_locale
					banner {
						backgroundImage {
							fluid(maxWidth: 1399, maxHeight: 750, quality: 60) {
								src
								srcWebp
							}
						}
					}
				}
			}
		}
		lineSm: allContentfulPage(filter: { slug: { eq: $slug } }) {
			edges {
				node {
					node_locale
					banner {
						backgroundImage {
							fluid(maxWidth: 767, maxHeight: 440, quality: 60) {
								src
								srcWebp
							}
						}
					}
				}
			}
		}
		lastBanner: allContentfulBanner(
			filter: { contentful_id: { eq: "5UBkM4QSv0bHOcKjyMBmw1" } }
		) {
			edges {
				node {
					hook
					node_locale
					mainActionText
					mainActionUrl
					description {
						json
					}
					backgroundImage {
						file {
							url
						}
					}
				}
			}
		}
		productImage: allContentfulProduct(filter: { series: { eq: $series } }) {
			edges {
				node {
					node_locale
					series
					seoTitle
					slug
					model
					categories {
						slug
					}
					images {
						fluid(maxWidth: 600, maxHeight: 400, quality: 60) {
							src
							srcWebp
						}
						description
					}
				}
			}
		}
	}
`

export default ProductLines
