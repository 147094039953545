import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Spinner from '../../components/utils/spinner'
import { Query } from 'react-apollo'
import { Client } from '../../apollo/client'
import { gql } from 'apollo-boost'
import AddToCart from './add-to-cart'
import isEmpty from 'lodash.isempty'
import { onProductClick } from '../utils/analytics'
import { navigate } from 'gatsby'
import {
	MainSection,
	ProductsContainer,
	Main,
	ProductsInnerContainer,
	ItemDetails,
	Continue,
	NullText,
	MainItemContainer,
	ItemContainer,
	ItemInnerContainer,
	ItemImageContainer,
	ItemImage,
	DetailsContainer,
	ProductName,
	AddToCartContainer,
	Price,
	Feature,
	SubItemContainer,
	SubItemsContainer,
	SubItemsInnerContainer,
	SubItemsDetails,
	SubItemBtns,
	SubItemImageContainer,
	SubItemAddToCartContainer,
	SubItemDetails,
	TrialRequest,
	Request,
	SubTrialRequest,
	EnquireNow,
	Enquire,
	SubEnquire,
	MainDetailsContainer,
	MainProductName,
	MainFeature,
} from './styles'

const Products = ({
	productImage,
	prodSeries,
	locale,
	checkedLocale,
	currency,
}) => {
	const filterAllByLocale = (items, locale) => {
		return items
			.filter((item, i) => {
				return (
					item.node.node_locale === locale && item.node.series === prodSeries
				)
			})
			.filter((item, i) => {
				return item.node.categories === null
					? item
					: item.node.categories?.slug != 'spare-parts'
			})
	}

	const filterByCountry = (items, locale) => {
		if (items.length > 0) {
			return items.filter((x, i) => {
				if (Object.keys(x).length === 0) {
					return null
				} else {
					const localeSplit = locale.split('-')
					const langCode = localeSplit[1]
					const check = x.countryAvailability.indexOf(langCode)
					return check >= 0 ? x : null
				}
			})
		} else {
			return []
		}
	}

	const isBrowser = typeof window !== 'undefined'
	const [items, setItems] = useState([])
	useEffect(() => {
		/**
		 * Google Analytics - Tag Manager - Enhanced Ecommerce
		 * Measuring product impressions for every search result change
		 * */
		const floatPrice = price =>
			price >= 1 ? parseFloat(price).toFixed(2) : parseFloat(0).toFixed(2)
		let impressions = items.map((item, index) => ({
			name: item.seoTitle, // the name of the product, including Title and Subtitle
			id: item.sku, // product serial number
			price: Number(floatPrice(item.price)), // actual price per unit or in case of fixed price discounts the discounted price
			brand: item.brand, // product brand
			category: [item.categories && item.categories.title].toString(), // Dolphin domestic, Dolphin commercial, mineral swim, ozone swim
			list: 'Category List', // the type of list for the current product impression )e.g. Category List, Search Results etc.)
			position: index + 1, // the position of the product within the list
		}))
		if (isBrowser && window.dataLayer && items.length >= 1) {
			window.dataLayer.push({
				event: 'productImpression',
				ecommerce: {
					currencyCode: currency,
					impressions,
				},
			})
		}
	}, [items])
	const reverse = prodSeries === 'PRO Series' || prodSeries === 'O₃ Series'
	const getCountry = locale.split('-')[1].toLowerCase()
	return (
		<MainSection>
			<ProductsContainer>
				<Query
					query={APOLLO_PRODUCT_LINE_QUERY}
					variables={{ series: prodSeries, locale: locale }}
					client={Client}
				>
					{({ data, loading, error }) => {
						if (loading) {
							return <Spinner />
						}
						if (error) {
							return <NullText>Something Went Wrong!</NullText>
						}

						const {
							productCollection: { items },
						} = data
						setItems(() => {
							return reverse ? items.reverse() : items
						})
						const filteredImages = filterAllByLocale(productImage.edges, locale)
						let overrideItems = filterByCountry(items, locale)
							.map(item => {
								return { ...item, val: 1 }
							})
							.filter((item, i) => {
								return item.categories === null
									? item
									: item.categories.slug != 'spare-parts'
							})
							.sort((a, b) => {
								return b.price - a.price
							})
						const [{ ...firstItem }, ...rest] = overrideItems
						const mainItems = [firstItem]
						const otherItems = [...rest]
						const remainder = [...rest].length % 3
						return (
							<Main>
								{overrideItems.length >= 4 ? (
									<ProductsInnerContainer>
										<MainItemContainer>
											{items.length > 0 ? (
												mainItems.map((item, i) => {
													if (!isEmpty(item)) {
														let title = item.seoTitle
														let titleSplit = title.split(' ')
														let productBrand = titleSplit.find(
															ttl =>
																ttl === 'Dolphin' ||
																ttl === 'Mineral' ||
																ttl === 'Ozone'
														)
														let pBrand =
															productBrand === 'Dolphin'
																? productBrand
																: productBrand === 'Mineral'
																? 'Mineral Swim'
																: productBrand === 'Ozone'
																? 'Ozone Swim'
																: ''

														let itemName = item.seoTitle.split(pBrand).slice(1)

														const filterImg = img => {
															return img.filter(x => {
																return x.node.slug === item.slug ? x : ''
															})
														}
														const filteredByslug = filterImg(filteredImages)
														return (
															<ItemContainer key={i}>
																<ItemInnerContainer>
																	<ItemImageContainer
																		onClick={() => {
																			onProductClick(
																				{
																					...item,
																					list: 'Category List', // the type of list for the current product impression )e.g. Category List, Search Results etc.)
																					position: i + 1, // the position of the product within the list
																				},
																				'Category List',
																				i,
																				navigate(
																					`${checkedLocale}/product/${item.slug}`
																				)
																			)
																		}}
																	>
																		<ItemImage>
																			<source
																				srcSet={
																					filteredByslug[0].node.images[0].fluid
																						.srcWebp
																				}
																				type="image/webp"
																			/>
																			<source
																				srcSet={`${
																					filteredByslug[0].node.images[0].fluid.src.split(
																						'?'
																					)[0]
																				}?fm=png&fl=png8&fit=pad&w=600&h=400`}
																			/>
																			<img
																				src={
																					filteredByslug[0].node.images[0].fluid
																						.src
																				}
																				alt={
																					filteredByslug[0].node.images[0]
																						.description
																				}
																			/>
																		</ItemImage>
																	</ItemImageContainer>
																	<MainDetailsContainer>
																		<DetailsContainer>
																			<MainProductName>
																				<span>{pBrand}</span>
																				{itemName}
																			</MainProductName>
																			{item.featuresCollection.items.map(
																				(feature, i) => {
																					return (
																						<MainFeature
																							key={`${feature.title}${i}`}
																						>
																							{feature.title}
																						</MainFeature>
																					)
																				}
																			)}
																		</DetailsContainer>
																		{item.active === null ||
																		item.active === true ? (
																			prodSeries != 'Wave' ? (
																				<AddToCartContainer>
																					<Price>
																						{item.price.toLocaleString(locale, {
																							style: 'currency',
																							currency: currency,
																						})}
																					</Price>
																					<AddToCart items={item} index={i} />
																				</AddToCartContainer>
																			) : (
																				<TrialRequest
																					to={`${checkedLocale}/support/contact/?subject=commercial+trial+${encodeURIComponent(
																						item.model.toLowerCase()
																					)}#contact-form`}
																				>
																					<Request>Request a Trial</Request>
																				</TrialRequest>
																			)
																		) : prodSeries != 'Wave' ? (
																			locale === 'en-AU' ||
																			prodSeries === 'E Line' ? (
																				<EnquireNow
																					to={`${checkedLocale}/support/contact/?subject=${encodeURIComponent(
																						item.seoTitle.toLowerCase()
																					)}#contact-form`}
																				>
																					<Enquire>Enquire now</Enquire>
																				</EnquireNow>
																			) : (
																				<EnquireNow
																					to={`https://mydolphin.co.${getCountry}`}
																				>
																					<Enquire>Buy at MyDolphin</Enquire>
																				</EnquireNow>
																			)
																		) : (
																			<EnquireNow
																				to={`${checkedLocale}/support/contact/?subject=commercial+trial+${encodeURIComponent(
																					item.model.toLowerCase()
																				)}#contact-form`}
																			>
																				<Enquire>
																					{locale === 'en-AU'
																						? 'Request a Trial'
																						: 'Enquire now'}
																				</Enquire>
																			</EnquireNow>
																		)}
																		<ItemDetails
																			onClick={() => {
																				onProductClick(
																					{
																						...item,
																						list: 'Category List', // the type of list for the current product impression )e.g. Category List, Search Results etc.)
																						position: i + 1, // the position of the product within the list
																					},
																					'Category List',
																					i,
																					navigate(
																						`${checkedLocale}/product/${item.slug}/`
																					)
																				)
																			}}
																		>
																			<Continue>View Product</Continue>
																		</ItemDetails>
																	</MainDetailsContainer>
																</ItemInnerContainer>
															</ItemContainer>
														)
													}
												})
											) : (
												<NullText>No items can be found!</NullText>
											)}
										</MainItemContainer>
										{otherItems.length > 0 ? (
											<SubItemContainer>
												{items.length > 0
													? otherItems.map((item, i) => {
															if (!isEmpty(item)) {
																let title = item.seoTitle
																let titleSplit = title.split(' ')
																let productBrand = titleSplit.find(
																	ttl =>
																		ttl === 'Dolphin' ||
																		ttl === 'Mineral' ||
																		ttl === 'Ozone'
																)
																let pBrand =
																	productBrand === 'Dolphin'
																		? productBrand
																		: productBrand === 'Mineral'
																		? 'Mineral Swim'
																		: productBrand === 'Ozone'
																		? 'Ozone Swim'
																		: ''

																let itemName = item.seoTitle
																	.split(pBrand)
																	.slice(1)

																const filterImg = img => {
																	return img.filter(x => {
																		return x.node.slug === item.slug ? x : ''
																	})
																}

																const filteredByslug = filterImg(filteredImages)
																return (
																	<SubItemsContainer
																		key={i}
																		wid={remainder === 1}
																	>
																		<SubItemsInnerContainer
																			className={
																				otherItems.length > 1 ? '' : 'notAuto'
																			}
																		>
																			<SubItemsDetails
																				className={
																					otherItems.length > 1 ? '' : 'notAuto'
																				}
																			>
																				<SubItemImageContainer
																					onClick={() => {
																						onProductClick(
																							{
																								...item,
																								list: 'Category List', // the type of list for the current product impression )e.g. Category List, Search Results etc.)
																								position:
																									i + 1 + mainItems.length, // the position of the product within the list
																							},
																							'Category List',
																							i + mainItems.length,
																							navigate(
																								`${checkedLocale}/product/${item.slug}`
																							)
																						)
																					}}
																				>
																					<ItemImage>
																						<source
																							srcSet={
																								filteredByslug[0].node.images[0]
																									.fluid.srcWebp
																							}
																							type="image/webp"
																						/>
																						<source
																							srcSet={`${
																								filteredByslug[0].node.images[0].fluid.src.split(
																									'?'
																								)[0]
																							}?fm=png&fl=png8&fit=pad&w=600&h=400`}
																						/>
																						<img
																							src={
																								filteredByslug[0].node.images[0]
																									.fluid.src
																							}
																							alt={
																								filteredByslug[0].node.images[0]
																									.description
																							}
																						/>
																					</ItemImage>
																				</SubItemImageContainer>
																				<DetailsContainer
																					className={
																						otherItems.length > 1
																							? ''
																							: 'notAuto'
																					}
																				>
																					<ProductName>
																						<span>{pBrand}</span>
																						{itemName}
																					</ProductName>
																					{item.featuresCollection.items.map(
																						(feature, i) => {
																							return (
																								<Feature
																									key={`${feature.title}${i}`}
																								>
																									{feature.title}
																								</Feature>
																							)
																						}
																					)}
																				</DetailsContainer>
																				<SubItemBtns
																					className={
																						otherItems.length > 1
																							? ''
																							: 'notAuto'
																					}
																				>
																					{item.active === null ||
																					item.active === true ? (
																						prodSeries != 'Wave' ? (
																							<SubItemAddToCartContainer>
																								<Price>
																									{item.price.toLocaleString(
																										locale,
																										{
																											style: 'currency',
																											currency: currency,
																										}
																									)}
																								</Price>
																								<AddToCart
																									items={item}
																									index={i}
																								/>
																							</SubItemAddToCartContainer>
																						) : (
																							<SubTrialRequest
																								to={`${checkedLocale}/support/contact/?subject=commercial+trial+${item.model}#contact-form`}
																							>
																								<Request>
																									Request a Trial
																								</Request>
																							</SubTrialRequest>
																						)
																					) : prodSeries != 'Wave' ? (
																						locale === 'en-AU' ||
																						prodSeries === 'E Line' ? (
																							<SubEnquire
																								to={`${checkedLocale}/support/contact/?subject=${encodeURIComponent(
																									item.seoTitle.toLowerCase()
																								)}#contact-form`}
																							>
																								<Enquire>Enquire now</Enquire>
																							</SubEnquire>
																						) : (
																							<SubEnquire
																								to={`https://mydolphin.co.${getCountry}`}
																							>
																								<Enquire>
																									Buy at MyDolphin
																								</Enquire>
																							</SubEnquire>
																						)
																					) : (
																						<SubEnquire
																							to={`${checkedLocale}/support/contact/?subject=commercial+trial+${encodeURIComponent(
																								item.model.toLowerCase()
																							)}#contact-form`}
																						>
																							<Enquire>
																								{locale === 'en-AU'
																									? 'Request a Trial'
																									: 'Enquire now'}
																							</Enquire>
																						</SubEnquire>
																					)}
																					<SubItemDetails
																						onClick={() => {
																							onProductClick(
																								{
																									...item,
																									list: 'Category List', // the type of list for the current product impression )e.g. Category List, Search Results etc.)
																									position:
																										i + 1 + mainItems.length, // the position of the product within the list
																								},
																								'Category List',
																								i + mainItems.length,
																								navigate(
																									`${checkedLocale}/product/${item.slug}/`
																								)
																							)
																						}}
																					>
																						<Continue>View Product</Continue>
																					</SubItemDetails>
																				</SubItemBtns>
																			</SubItemsDetails>
																		</SubItemsInnerContainer>
																	</SubItemsContainer>
																)
															}
													  })
													: null}
											</SubItemContainer>
										) : (
											''
										)}
									</ProductsInnerContainer>
								) : (
									<SubItemContainer>
										{items.length > 0
											? overrideItems.map((item, i) => {
													if (!isEmpty(item)) {
														let title = item.seoTitle
														let titleSplit = title.split(' ')
														let productBrand = titleSplit.find(
															ttl =>
																ttl === 'Dolphin' ||
																ttl === 'Mineral' ||
																ttl === 'Ozone'
														)
														let pBrand =
															productBrand === 'Dolphin'
																? productBrand
																: productBrand === 'Mineral'
																? 'Mineral Swim'
																: productBrand === 'Ozone'
																? 'Ozone Swim'
																: ''

														let itemName = item.seoTitle.split(pBrand).slice(1)

														const filterImg = img => {
															return img.filter(x => {
																return x.node.slug === item.slug ? x : ''
															})
														}

														const filteredByslug = filterImg(filteredImages)
														return (
															<SubItemsContainer key={i}>
																<SubItemsInnerContainer
																	className={
																		otherItems.length > 1 ? '' : 'notAuto'
																	}
																>
																	<SubItemsDetails
																		className={
																			otherItems.length > 1 ? '' : 'notAuto'
																		}
																	>
																		<SubItemImageContainer
																			onClick={() => {
																				onProductClick(
																					{
																						...item,
																						list: 'Category List', // the type of list for the current product impression )e.g. Category List, Search Results etc.)
																						position: i + 1 + mainItems.length, // the position of the product within the list
																					},
																					'Category List',
																					i + mainItems.length,
																					navigate(
																						`${checkedLocale}/product/${item.slug}`
																					)
																				)
																			}}
																		>
																			<ItemImage>
																				<source
																					srcSet={
																						filteredByslug[0].node.images[0]
																							.fluid.srcWebp
																					}
																					type="image/webp"
																				/>
																				<source
																					srcSet={`${
																						filteredByslug[0].node.images[0].fluid.src.split(
																							'?'
																						)[0]
																					}?fm=png&fl=png8&fit=pad&w=600&h=400`}
																				/>
																				<img
																					src={
																						filteredByslug[0].node.images[0]
																							.fluid.src
																					}
																					alt={
																						filteredByslug[0].node.images[0]
																							.description
																					}
																				/>
																			</ItemImage>
																		</SubItemImageContainer>
																		<DetailsContainer
																			className={
																				otherItems.length > 1 ? '' : 'notAuto'
																			}
																		>
																			<ProductName>
																				<span>{pBrand}</span>
																				{itemName}
																			</ProductName>
																			{item.featuresCollection.items.map(
																				(feature, i) => {
																					return (
																						<Feature
																							key={`${feature.title}${i}`}
																						>
																							{feature.title}
																						</Feature>
																					)
																				}
																			)}
																		</DetailsContainer>
																		<SubItemBtns
																			className={
																				otherItems.length > 1 ? '' : 'notAuto'
																			}
																		>
																			{item.active === null ||
																			item.active === true ? (
																				prodSeries != 'Wave' ? (
																					<SubItemAddToCartContainer>
																						<Price>
																							{item.price.toLocaleString(
																								locale,
																								{
																									style: 'currency',
																									currency: currency,
																								}
																							)}
																						</Price>
																						<AddToCart items={item} index={i} />
																					</SubItemAddToCartContainer>
																				) : (
																					<SubTrialRequest
																						to={`${checkedLocale}/support/contact/?subject=commercial+trial+${item.model}#contact-form`}
																					>
																						<Request>Request a Trial</Request>
																					</SubTrialRequest>
																				)
																			) : prodSeries != 'Wave' ? (
																				locale === 'en-AU' ||
																				prodSeries === 'E Line' ? (
																					<SubEnquire
																						to={`${checkedLocale}/support/contact/?subject=${encodeURIComponent(
																							item.seoTitle.toLowerCase()
																						)}#contact-form`}
																					>
																						<Enquire>Enquire now</Enquire>
																					</SubEnquire>
																				) : (
																					<SubEnquire
																						to={`https://mydolphin.co.${getCountry}`}
																					>
																						<Enquire>Buy at MyDolphin</Enquire>
																					</SubEnquire>
																				)
																			) : (
																				<SubEnquire
																					to={`${checkedLocale}/support/contact/?subject=commercial+trial+${encodeURIComponent(
																						item.model.toLowerCase()
																					)}#contact-form`}
																				>
																					<Enquire>
																						{locale === 'en-AU'
																							? 'Request a Trial'
																							: 'Enquire now'}
																					</Enquire>
																				</SubEnquire>
																			)}
																			<SubItemDetails
																				onClick={() => {
																					onProductClick(
																						{
																							...item,
																							list: 'Category List', // the type of list for the current product impression )e.g. Category List, Search Results etc.)
																							position:
																								i + 1 + mainItems.length, // the position of the product within the list
																						},
																						'Category List',
																						i + mainItems.length,
																						navigate(
																							`${checkedLocale}/product/${item.slug}/`
																						)
																					)
																				}}
																			>
																				<Continue>View Product</Continue>
																			</SubItemDetails>
																		</SubItemBtns>
																	</SubItemsDetails>
																</SubItemsInnerContainer>
															</SubItemsContainer>
														)
													}
											  })
											: null}
									</SubItemContainer>
								)}
							</Main>
						)
					}}
				</Query>
			</ProductsContainer>
		</MainSection>
	)
}

const APOLLO_PRODUCT_LINE_QUERY = gql`
	query prodLineQuery($series: String!, $locale: String!) {
		productCollection(
			where: { series: $series }
			locale: $locale
			order: [price_DESC]
		) {
			items {
				seoTitle
				seoDescription
				slug
				sku
				price
				model
				brand
				active
				popularity
				countryAvailability
				categories {
					title: seoTitle
					slug
				}
				shippingClass
				imagesCollection(limit: 1) {
					items {
						url
						description
					}
				}
				featuresCollection(limit: 3) {
					items {
						title
					}
				}
			}
		}
	}
`

Products.propTypes = {
	productImage: PropTypes.any,
	prodSeries: PropTypes.string,
	locale: PropTypes.string,
}

export default Products
