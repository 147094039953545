import React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { LineDescriptionContainer, Heading2, Text } from './styles'

const LineDescription = ({ body }) => {

  const options = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => {
        const words = [
          "Dolphin", "Mineral Swim"
        ]
        const x = words.map((x, i) => {
          if ( children[0].includes(x) ) {
            const suffix = children[0].split(x)[1]
            return `${x}`
          }
        })
        const ind = children[0].includes('Dolphin') ? 0 : 1
        const suffix = children[0].split(x[ind])
        return <Heading2>{x[ind]}<span>{suffix}</span></Heading2>
      },
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
  }
    
  return (
		<LineDescriptionContainer>
			{documentToReactComponents(body.json, options)}
		</LineDescriptionContainer>
	)
}

export default LineDescription
