import React, { useState } from 'react'
import useCart from '../../hooks/useCart'
import useTheme from '../../hooks/theme'
import { onAddToCart } from '../utils/analytics'
import { Button } from './styles'

const AddToCart = ({ items, index }) => {
	const newItems = { ...items, index: index }

	const [value, setValue] = useState(newItems)

	const { addToCart } = useCart()
	const { openCart } = useTheme()

	const {
		imagesCollection: { items: itemImage },
	} = value
	const item = {
		img: itemImage[0].url,
		slug: value.slug,
		name: value.seoTitle,
		sku: value.sku,
		brand: value.brand,
		category: [value.categories && value.categories.title].toString(),
		price: Number(value.price * 100),
		quantity: value.val,
		shippingClass: value.shippingClass,
	}
	return (
		<>
			<Button
				onClick={() => {
					addToCart(item)
					onAddToCart(item, 'Category List')
					openCart()
				}}
				onKeyDown={() => {
					addToCart(item)
					onAddToCart(item, 'Category List')
					openCart()
				}}
				disabled={value.val < 1}
			>
				Add to cart
			</Button>
		</>
	)
}

export default AddToCart
